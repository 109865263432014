<template>
	<div>
		<CardListBuilder custom-class="hide-on-desktop" :items="imgData" :fields="docMobileFields">
			<template slot="info">
				<div class="text-left px-1" style="font-weight: 600; color: rgba(0, 0, 0, 0.6); border-bottom: 1px dotted rgba(0, 0, 0, 0.15)">
					{{ FormMSG(3, 'Picture') }}
				</div>
			</template>
			<template slot="actions" slot-scope="data">
				<div class="mr-2 mt-1" @click="showImg(data.item.filename)">
					<img :src="getImage(data.item.filename)" class="task-type-image-no-radius" alt="photo" />
				</div>
				<b-button size="sm" variant="danger" @click="removeImg(data.item.filename)">
					<i class="icon-trash" />
				</b-button>
			</template>
		</CardListBuilder>
		<b-table
			ref="tableImages"
			class="hide-on-tablet"
			:hover="true"
			responsive="sm"
			selectable
			selected-variant="primary"
			select-mode="single"
			sticky-header="400px"
			bordered
			small
			head-variant="dark"
			:fields="docfields"
			:items="imgData"
		>
			<template v-slot:cell(filename)="data">
				<div>
					<img :src="getImage(data.item.filename)" class="task-type-image-no-radius" alt="photo" />
				</div>
			</template>
			<template #cell(actions)="data">
				<b-button variant="primary" class="mr-2" size="sm" @click="showImg(data.item.filename)">
					<i class="icon-eye" />
				</b-button>
				<b-button variant="danger" size="sm" @click="removeImg(data.item.filename)">
					<i class="icon-trash" />
				</b-button>
			</template>
		</b-table>

		<b-modal
			header-class="header-class-modal-doc-package"
			v-model="openShowRoomPicModal"
			:title="FormMSG(3, 'Picture')"
			class="modal-success"
			ok-variant="success"
			ok-only
			@ok="openShowRoomPicModal = false"
			modal-class="mui-animation"
			:fade="false"
		>
			<div>
				<b-img :src="imgToShow" fluid-grow />
			</div>
		</b-modal>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import axios from 'axios';
import { store } from '@/store';

export default {
	name: 'ImgShowroomTable',

	mixins: [languageMessages, globalMixin],

	props: {
		imgData: { type: Array, required: true, default: () => [] }
	},

	data() {
		return {
			openShowRoomPicModal: false,
			imgToShow: ''
		};
	},

	computed: {
		baseFields() {
			return [
				{
					key: 'filename',
					label: this.FormMSG(3, 'Picture'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'actions',
					label: this.FormMSG(2, 'Actions'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				}
			];
		},
		docfields() {
			return this.baseFields;
		},
		docMobileFields() {
			const keys = ['filename', 'actions'];
			return this.baseFields.filter((t) => !keys.includes(t.key));
		}
	},

	methods: {
		showImg(imageName) {
			this.imgToShow = `${process.env.VUE_APP_GQL}/images/${imageName}`;
			this.openShowRoomPicModal = true;
		},
		removeImg(imgName) {
			// alert('Je suis ici')
			let action = () => {
				let retStr = '';
				let fileName = imgName.lastIndexOf('.') === -1 ? imgName : imgName.substring(0, imgName.lastIndexOf('.'));
				let formData = new FormData(); // instantiate it
				formData.append('fileName', fileName);
				const config = {
					headers: {
						Authorization: `Bearer ${store.state.token}`,
						'content-type': 'multipart/form-data'
					}
				};
				const urlStr = process.env.VUE_APP_GQL + '/removedocument';
				axios
					.post(urlStr, formData, config)
					.then((response) => {
						retStr = response.data;

						this.$emit('image:removed', formData.get('fileName'));

						this.$toast({
							message: this.FormMSG(4, 'Element removed successfully'),
							title: this.FormMSG(5, 'Success'),
							variant: 'success'
						});
					})
					.catch((e) => console.log(e));
				return retStr;
			};
			this.confirmModal(action, this.FormMSG(6, 'Are you sure ?'));
		},
		getImage(filename) {
			let urlImg = '';
			try {
				urlImg = process.env.VUE_APP_GQL + '/images/' + filename;
			} catch (e) {}

			return urlImg;
		}
	}
};
</script>

<style scoped></style>
