var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "CardListBuilder",
        {
          attrs: {
            "custom-class": "hide-on-desktop",
            items: _vm.imgData,
            fields: _vm.docMobileFields,
          },
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function (data) {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "mr-2 mt-1",
                      on: {
                        click: function ($event) {
                          return _vm.showImg(data.item.filename)
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "task-type-image-no-radius",
                        attrs: {
                          src: _vm.getImage(data.item.filename),
                          alt: "photo",
                        },
                      }),
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.removeImg(data.item.filename)
                        },
                      },
                    },
                    [_c("i", { staticClass: "icon-trash" })]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("template", { slot: "info" }, [
            _c(
              "div",
              {
                staticClass: "text-left px-1",
                staticStyle: {
                  "font-weight": "600",
                  color: "rgba(0, 0, 0, 0.6)",
                  "border-bottom": "1px dotted rgba(0, 0, 0, 0.15)",
                },
              },
              [
                _vm._v(
                  "\n\t\t\t\t" + _vm._s(_vm.FormMSG(3, "Picture")) + "\n\t\t\t"
                ),
              ]
            ),
          ]),
        ],
        2
      ),
      _c("b-table", {
        ref: "tableImages",
        staticClass: "hide-on-tablet",
        attrs: {
          hover: true,
          responsive: "sm",
          selectable: "",
          "selected-variant": "primary",
          "select-mode": "single",
          "sticky-header": "400px",
          bordered: "",
          small: "",
          "head-variant": "dark",
          fields: _vm.docfields,
          items: _vm.imgData,
        },
        scopedSlots: _vm._u([
          {
            key: "cell(filename)",
            fn: function (data) {
              return [
                _c("div", [
                  _c("img", {
                    staticClass: "task-type-image-no-radius",
                    attrs: {
                      src: _vm.getImage(data.item.filename),
                      alt: "photo",
                    },
                  }),
                ]),
              ]
            },
          },
          {
            key: "cell(actions)",
            fn: function (data) {
              return [
                _c(
                  "b-button",
                  {
                    staticClass: "mr-2",
                    attrs: { variant: "primary", size: "sm" },
                    on: {
                      click: function ($event) {
                        return _vm.showImg(data.item.filename)
                      },
                    },
                  },
                  [_c("i", { staticClass: "icon-eye" })]
                ),
                _c(
                  "b-button",
                  {
                    attrs: { variant: "danger", size: "sm" },
                    on: {
                      click: function ($event) {
                        return _vm.removeImg(data.item.filename)
                      },
                    },
                  },
                  [_c("i", { staticClass: "icon-trash" })]
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "b-modal",
        {
          staticClass: "modal-success",
          attrs: {
            "header-class": "header-class-modal-doc-package",
            title: _vm.FormMSG(3, "Picture"),
            "ok-variant": "success",
            "ok-only": "",
            "modal-class": "mui-animation",
            fade: false,
          },
          on: {
            ok: function ($event) {
              _vm.openShowRoomPicModal = false
            },
          },
          model: {
            value: _vm.openShowRoomPicModal,
            callback: function ($$v) {
              _vm.openShowRoomPicModal = $$v
            },
            expression: "openShowRoomPicModal",
          },
        },
        [
          _c(
            "div",
            [_c("b-img", { attrs: { src: _vm.imgToShow, "fluid-grow": "" } })],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }